import { twMerge } from "tailwind-merge";

export interface Props extends React.ButtonHTMLAttributes<HTMLHeadingElement> {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  lookLike?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const classes = {
  h1: "text-3xl font-bold text-gray-800",
  h2: "text-2xl font-bold text-gray-800",
  h3: "text-xl",
  h4: "text-lg",
  h5: "text-md",
  h6: "",
};

const Heading: React.FC<Props> = ({
  tag = "h1",
  lookLike,
  className,
  ...props
}) => {
  const Tag = tag;
  if (!lookLike) {
    lookLike = tag;
  }
  return (
    <Tag
      {...props}
      className={twMerge("tracking-wide", classes[lookLike], className)}
    >
      {props.children}
    </Tag>
  );
};
export default Heading;
